import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import esp from "../../static/img/flag-spanish.png"
import eng from "../../static/img/flag-english.png"
import { FormattedMessage, useIntl } from "react-intl"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

let lang = "";
let labelLang = "";

interface ContextConsumerProps {
  languages: string[]
  language: string
}

interface HomeProps {
  data?: any
  intl?: any
  siteTitle: string,
  bg: string
}

const Header = ({ data, intl, siteTitle, bg }: HomeProps) => {

  lang = useIntl().locale;

  if( useIntl().locale === "es") {
    labelLang = "Esp";
  } else {
    labelLang = "Eng";
  }

  const nav = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const ham = React.useRef() as React.MutableRefObject<HTMLButtonElement>
  const header = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const topHeader = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const langSwtich = React.useRef() as React.MutableRefObject<HTMLDivElement>

  const [scroll] = useState(0);
  const [load] = useState(0);

  let statusLang = false;

  const getLabelLang = (language: string) => {
    let label = "";
    if( language === "es") {
      label = "Español";
    } else {
      label = "English";
    }
    return label;
  }

  const showHiddeLang = (status: boolean) => {
    let timeout: any;
    if(!status) {
      langSwtich.current.style.display = "block";
      timeout = setTimeout(() => {
        if(langSwtich.current) {
          langSwtich.current.style.display = "none";
          statusLang = !statusLang;
        }
      }, 5000);
    } else {
      langSwtich.current.style.display = "none";
    }
    statusLang = !statusLang;
  }

  useEffect(() => {
    topHeader.current.style.height = header.current.offsetHeight + "px";
  }, [load]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scroll])

  const toggleMenu = (e: any) => {
    ham.current.classList.toggle("is-active")
    nav.current.classList.toggle("is-active")
  }

  const onScroll = (e: any) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > 0) {
      header.current.classList.add("sticky");
      topHeader.current.style.display = "block"
    } else {
      header.current.classList.remove("sticky");
      topHeader.current.style.display = "none";
    }
  }

  return (
    <>
    <header ref={header} className={"container-small " + bg }>
      <figure>
        <Link to={`/${lang}` + "/"} activeClassName="active">
          {/* <img src={ require("../assets/img/logo-attica.svg")} alt="Attica Labs" className="app-logo" /> */}
          <svg width="142" height="49" viewBox="0 0 142 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65.2051 35.3173L64.9527 33.0492C64.3036 33.9202 63.438 34.6097 62.3561 35.1177C61.2742 35.6077 60.1022 35.8617 58.8219 35.8617C57.0007 35.8617 55.3959 35.4443 53.9714 34.5915C52.5469 33.7387 51.429 32.5593 50.6175 31.0533C49.8061 29.5472 49.4094 27.8234 49.4094 25.9001C49.4094 23.9586 49.8061 22.2348 50.6175 20.7288C51.429 19.2409 52.5469 18.0614 53.9714 17.2086C55.3959 16.3558 57.0188 15.9385 58.8219 15.9385C60.1022 15.9385 61.2742 16.1925 62.3381 16.6824C63.402 17.1905 64.2675 17.8618 64.9527 18.751L65.2051 16.4828H69.7311V35.3173H65.2051ZM59.6514 31.2166C61.13 31.2166 62.3201 30.7266 63.2577 29.7287C64.1954 28.7307 64.6462 27.4605 64.6462 25.9001C64.6462 24.3396 64.1773 23.0695 63.2577 22.0715C62.3201 21.0735 61.13 20.5836 59.6514 20.5836C58.1728 20.5836 56.9647 21.0735 56.0451 22.0715C55.1074 23.0695 54.6566 24.3396 54.6566 25.9001C54.6566 27.4605 55.1255 28.7307 56.0451 29.7287C56.9827 30.7266 58.1908 31.2166 59.6514 31.2166Z" fill="#3B3BFF"/>
            <path d="M90.3888 16.607V10.7578H85.2685V16.607H82.9626H82.9263H79.7125V10.7578H74.6104V16.607H71.7234V20.9849H74.6104V35.4626H79.7125V20.9849H82.9263H82.9626H85.2685V35.4626H90.3888V20.9849H93.6389V16.607H90.3888Z" fill="#3B3BFF"/>
            <path d="M118.118 29.0755L121.133 32.2871C119.099 34.6641 116.417 35.8617 113.106 35.8617C111.09 35.8617 109.314 35.4443 107.761 34.5915C106.207 33.7387 104.986 32.5593 104.117 31.0533C103.229 29.5472 102.804 27.8234 102.804 25.9001C102.804 23.9767 103.229 22.2711 104.098 20.765C104.968 19.2772 106.17 18.0977 107.687 17.2268C109.203 16.3558 110.942 15.9385 112.884 15.9385C116.176 15.9385 118.803 17.0272 120.726 19.2227L117.748 22.4344C116.509 21.3094 115.011 20.7469 113.291 20.7469C111.774 20.7469 110.553 21.2187 109.61 22.1622C108.667 23.1057 108.204 24.3578 108.204 25.8819C108.204 27.4424 108.667 28.6944 109.61 29.6198C110.553 30.5633 111.811 31.017 113.365 31.017C115.103 31.0351 116.694 30.3819 118.118 29.0755Z" fill="#3B3BFF"/>
            <path d="M137.327 35.3173L137.075 33.0492C136.426 33.9202 135.56 34.6097 134.478 35.1177C133.396 35.6077 132.224 35.8617 130.944 35.8617C129.123 35.8617 127.518 35.4443 126.093 34.5915C124.669 33.7387 123.551 32.5593 122.74 31.0533C121.928 29.5472 121.531 27.8234 121.531 25.9001C121.531 23.9586 121.928 22.2348 122.74 20.7288C123.551 19.2409 124.669 18.0614 126.093 17.2086C127.518 16.3558 129.141 15.9385 130.944 15.9385C132.224 15.9385 133.396 16.1925 134.46 16.6824C135.524 17.1905 136.39 17.8618 137.075 18.751L137.327 16.4828H141.853V35.3173H137.327ZM131.773 31.2166C133.252 31.2166 134.442 30.7266 135.38 29.7287C136.317 28.7307 136.768 27.4605 136.768 25.9001C136.768 24.3396 136.299 23.0695 135.38 22.0715C134.442 21.0735 133.252 20.5836 131.773 20.5836C130.295 20.5836 129.087 21.0735 128.167 22.0715C127.229 23.0695 126.779 24.3396 126.779 25.9001C126.779 27.4605 127.248 28.7307 128.167 29.7287C129.087 30.7266 130.295 31.2166 131.773 31.2166Z" fill="#3B3BFF"/>
            <path d="M95.6313 35.4622V16.7344H100.811V35.4622H95.6313Z" fill="#3B3BFF"/>
            <path d="M100.811 10.7578H95.6313V15.1409H100.811V10.7578Z" fill="#3B3BFF"/>
            <path d="M44.1563 37.9004L36.4652 24.0639L43.9731 10.2275C44.0464 10.1004 44.0464 9.9733 44.0098 9.8462C43.9731 9.71909 43.8633 9.61014 43.7534 9.55567L22.2 0.0408556C22.0718 -0.0136185 21.9254 -0.0136185 21.7972 0.0408556L0.280426 9.64646C0.152242 9.70094 0.0606807 9.80988 0.0240565 9.93699C-0.0125678 10.0641 -0.0125669 10.2094 0.0606816 10.3183L7.77008 24.1547L0.170554 37.9912C0.0973055 38.1183 0.0973061 38.2454 0.13393 38.3725C0.170555 38.4996 0.280426 38.6085 0.390299 38.663L22.0169 48.1778C22.0719 48.2141 22.1451 48.2141 22.2183 48.2141C22.2916 48.2141 22.3465 48.196 22.4198 48.1778L43.9548 38.5722C44.083 38.5177 44.1746 38.4088 44.2112 38.2817C44.2478 38.1546 44.2295 38.0093 44.1563 37.9004ZM17.4572 41.8588L5.48107 36.6293L9.98585 28.4582L17.4572 41.8588ZM5.3712 11.771L17.2558 6.4688L9.87598 19.8513L5.3712 11.771ZM12.2932 24.1547L21.907 6.63223L31.5209 24.0639L21.9986 41.5865L12.2932 24.1547ZM26.5583 6.45065L38.4612 11.6802L33.9564 19.8513L26.5583 6.45065ZM38.4429 36.5385L26.5583 41.8588L33.9381 28.4764L38.4429 36.5385Z" fill="#3B3BFF"/>
          </svg>
        </Link>
        { <figcaption>{ siteTitle }</figcaption> }
      </figure>
      <button
        ref={ham}
        onClick={toggleMenu}
        className="hamburger hamburger--arrow"
        type="button"
        aria-label="menu"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <nav ref={nav}>
        <ul>
          <li>
            <Link to={`/${lang}` + "/"} activeClassName="active">
              <FormattedMessage id="home" />
            </Link>
          </li>
          {/* <li>
            <Link to={`/${lang}` + "/services"} activeClassName="active">
              <FormattedMessage id="services" />
            </Link>
          </li> */}
          <li>
            <Link to={`/${ lang }` + "/portfolio"} activeClassName="active">
              <FormattedMessage id="portfolio" />
            </Link>
            {/* <a href={`/${ lang }` + "/#portfolio"}><FormattedMessage id="portfolio" /></a> */}
          </li>
          {/* <li>
            <Link to={`/${lang}` + "/culture"} activeClassName="active">
              <FormattedMessage id="culture" />
            </Link>
          </li> */}
          <li>
            <Link to={`/${lang}` + "/contact"} activeClassName="active">
              <FormattedMessage id="contact" />
            </Link>
            {/* <a href="mailto:hello@atticalabs.com" target="_blank">
              <FormattedMessage id="contact" />
            </a>*/}
          </li>
          <li className="langSwtich">
            <button onClick={ () => showHiddeLang(statusLang) }>{ labelLang }</button>
            <div ref={ langSwtich }>
                <IntlContextConsumer>
                  {({
                    languages,
                    language: currentLocale,
                  }: ContextConsumerProps) =>
                    languages.map((language: any, index) => (
                    <button className={ (language === currentLocale)? 'disabled' : '' } key={ index } onClick={ () => changeLocale(language) }><img src={ (language === 'es')? esp : eng } alt={ language } /> { getLabelLang(language) }</button>
                    ))
                  }
                </IntlContextConsumer>
            </div>
          </li>
        </ul>
      </nav>
    </header>
    {/* //TODO @blackweaver, no se para q se usa, pero al borrarlo da error den el html */}
    <div ref={ topHeader } className={"topHeader " + bg }></div>
    </>
  )
}

export default Header
